import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed w-screen z-10 py-3 bg-primary-500"
}
const _hoisted_2 = { class: "flex items-center justify-between mx-auto lg:w-11/12" }
const _hoisted_3 = { class: "text-base font-medium text-white" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "flex mx-auto" }
const _hoisted_6 = { class: "w-72" }
const _hoisted_7 = { class: "flex sm:flex-col md:flex-row lg:flex-row justify-between p-8 mb-4 bg-white border rounded-lg" }
const _hoisted_8 = { class: "grid lg:grid-cols-3 gap-4 flex-1 break-words mr-2" }
const _hoisted_9 = {
  key: 0,
  class: "p-8 mb-4 bg-white border rounded-lg flex justify-between items-center"
}
const _hoisted_10 = { class: "font-bold text-black text-xl" }
const _hoisted_11 = { class: "space-y-4" }
const _hoisted_12 = {
  key: 0,
  ref: "Review Summary",
  class: "p-8 bg-white border rounded-lg"
}
const _hoisted_13 = { class: "font-bold text-gray-900 text-xl leading-tight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PatientDetailsCard = _resolveComponent("PatientDetailsCard")!
  const _component_SummaryNav = _resolveComponent("SummaryNav")!
  const _component_SummaryItem = _resolveComponent("SummaryItem")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_SmartFormSummarySection = _resolveComponent("SmartFormSummarySection")!
  const _component_ReviewSummary = _resolveComponent("ReviewSummary")!
  const _component_PatientSupportingDocumentModal = _resolveComponent("PatientSupportingDocumentModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isPrevious)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.review.summary.history-message')), 1),
            _createVNode(_component_BaseButton, {
              "left-icon": "undo",
              color: "primary-600",
              size: "small",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentFormSubmissionId = _ctx.formSubmissionId))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.summary.return-to-review')), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["sticky top-0", _ctx.isPrevious ? 'py-20' : 'py-6'])
          }, [
            (_ctx.patient)
              ? (_openBlock(), _createBlock(_component_PatientDetailsCard, {
                  key: 0,
                  id: _ctx.patient.mrn,
                  "first-name": _ctx.patient.first_name,
                  "last-name": _ctx.patient.last_name,
                  infos: _ctx.patientInfos
                }, null, 8, ["id", "first-name", "last-name", "infos"]))
              : _createCommentVNode("", true),
            _createVNode(_component_SummaryNav, {
              class: "sticky top-0 py-3 mt-4",
              "model-value": _ctx.nav,
              items: _ctx.sections,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setNav($event)))
            }, null, 8, ["model-value", "items"]),
            (_ctx.isPrevious && _ctx.review && _ctx.review.completed_at)
              ? (_openBlock(), _createBlock(_component_SummaryNav, {
                  key: 1,
                  class: "sticky top-0 mt-4",
                  "model-value": _ctx.nav,
                  items: [
                {
                  id: 'Review Summary',
                  name: _ctx.$t('custom.uhb.review.summary.summary')
                }
              ],
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.setNav($event)))
                }, null, 8, ["model-value", "items"]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex-1 ml-6", _ctx.isPrevious ? 'py-20' : 'py-6'])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.clinic.length)
                ? (_openBlock(), _createBlock(_component_SummaryItem, {
                    key: 0,
                    label: _ctx.$t('custom.uhb.consult.clinic'),
                    value: _ctx.clinic
                  }, null, 8, ["label", "value"]))
                : _createCommentVNode("", true),
              (_ctx.clinician.length)
                ? (_openBlock(), _createBlock(_component_SummaryItem, {
                    key: 1,
                    label: _ctx.$t('custom.uhb.consult.clinician'),
                    value: _ctx.clinician
                  }, null, 8, ["label", "value"]))
                : _createCommentVNode("", true),
              (_ctx.consultType)
                ? (_openBlock(), _createBlock(_component_SummaryItem, {
                    key: 2,
                    label: _ctx.$t('custom.uhb.consult.consult-type'),
                    value: _ctx.consultType.name
                  }, null, 8, ["label", "value"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.date.length && _ctx.datesOptions.length < 2)
              ? (_openBlock(), _createBlock(_component_SummaryItem, {
                  key: 0,
                  label: _ctx.$t('platform.common.date'),
                  value: _ctx.formattedDate
                }, null, 8, ["label", "value"]))
              : (_openBlock(), _createBlock(_component_BaseSelect, {
                  key: 1,
                  modelValue: _ctx.currentFormSubmissionId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentFormSubmissionId) = $event)),
                  options: _ctx.datesOptions
                }, null, 8, ["modelValue", "options"]))
          ]),
          (_ctx.selectedDocumentId)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('custom.uhb.review.supporting-document')), 1),
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  "right-icon": "chevron-right",
                  onClick: _ctx.viewSupportingDocument
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.summary.view-document')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])), [
                [_directive_allow, 'consult:read']
              ])
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schema.sections, (section) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: section.id
              }, [
                (section.name && !section.hide_when_readonly && _ctx.conditionsMet(section))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      ref_for: true,
                      ref: section.id,
                      class: "p-8 bg-white border rounded-lg"
                    }, [
                      _createVNode(_component_SmartFormSummarySection, {
                        section: section,
                        "patient-id": _ctx.patientId,
                        "form-submission-id": _ctx.currentFormSubmissionId,
                        level: 0
                      }, null, 8, ["section", "patient-id", "form-submission-id"])
                    ], 512))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (_ctx.review && _ctx.review.completed_at)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("h1", _hoisted_13, _toDisplayString(_ctx.$t('custom.uhb.review.summary.summary')), 1),
                  _createVNode(_component_ReviewSummary, {
                    class: "mt-6",
                    "show-header": false,
                    "patient-id": _ctx.patientId,
                    "form-submission-id": _ctx.currentFormSubmissionId,
                    "review-id": _ctx.review.id,
                    onCopy: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCopy($event)))
                  }, null, 8, ["patient-id", "form-submission-id", "review-id"])
                ], 512))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ]),
      (_ctx.supportingDocumentVisible)
        ? (_openBlock(), _createBlock(_component_PatientSupportingDocumentModal, {
            key: 0,
            "document-id": _ctx.selectedDocumentId,
            onClose: _ctx.closeSupportingDocumentModal
          }, null, 8, ["document-id", "onClose"]))
        : _createCommentVNode("", true)
    ])
  ]))
}